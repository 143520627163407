import React, { useState } from "react";

import Home from "assets/home.svg";
import Arrow from "assets/arrow.jpg";
import { Link } from "gatsby";
import Layout from "components/layout";
import GetInTouch from "components/getInTouch";
import SEO from "components/seo";
import Breadcrumb from "components/LegalPolicies/Breadcrumb";
import CookiesTable from "components/LegalPolicies/CookiesTable";
import MobileTable from "components/LegalPolicies/MobileTable";
import backToTopIcon from "assets/backToTop.svg";
import ManageCookie from "components/popup/cookiePopup/ManageCookie";
import { useContext } from "react";
import DataContext from "Context/DataContext";

const allCookiesData = [
  {
    title: "Essential Cookies",
    description:
      "These are vital for website functionality and security. At Tomedes, these primarily support our chatbot, enabling real-time communication.",
    cookies: [
      {
        name: "twk_idm_key",
        duration: "Session",
        purpose: "Visitor connection management",
        providerPolicy: "Tawk.to Privacy Policy",
        url: "https://www.tawk.to/privacy-policy/",
      },
      {
        name: "TawkConnectionTime",
        duration: "Session",
        purpose: "Manages visitor connection",
        providerPolicy: "Tawk.to Privacy Policy",
        url: "https://www.tawk.to/privacy-policy/",
      },
      {
        name: "twk_uuid_599453f11b1bed47ceb04f29",
        duration: "6 months",
        purpose: "HTTP cookies",
        providerPolicy: "Tawk.to Privacy Policy",
        url: "https://www.tawk.to/privacy-policy/",
      },
    ],
  },
  {
    title: "Analytics and Performance Cookies",
    description:
      "These cookies provide insights into user behavior on our website. We use this data to optimize website performance and enhance the overall user experience.",
    cookies: [
      {
        name: "ln_or",
        duration: "1 day",
        purpose:
          "Used to determine if Oribi analytics can be carried out on a specific domain",
        providerPolicy: "LinkedIn Privacy Policy",
        url: "https://www.linkedin.com/legal/privacy-policy",
      },
      {
        name: "CLID",
        duration: "1 year",
        purpose:
          "Used to identify the first-time Clarity saw this user on any site using Clarity.",
        providerPolicy: "Microsoft Privacy Policy",
        url: "https://privacy.microsoft.com/en-us/privacystatement",
      },
      {
        name: "_clsk",
        duration: "1 day",
        purpose:
          "Used to store and combine pageviews by a user into a single session recording.",
        providerPolicy: "Microsoft Privacy Policy",
        url: "https://privacy.microsoft.com/en-us/privacystatement",
      },
      {
        name: "_ga_EYM278S4TB",
        duration: "2 years",
        purpose: "Used to persist session state",
        providerPolicy: "Google Privacy Policy",
        url: "https://policies.google.com/privacy",
      },
      {
        name: "_clck",
        duration: "1 year",
        purpose: "Used to store a unique user ID",
        providerPolicy: "Microsoft Privacy Policy",
        url: "https://privacy.microsoft.com/en-us/privacystatement",
      },
      {
        name: "_gid",
        duration: "1 day",
        purpose: "Used by Google Analytics to count and store pageviews",
        providerPolicy: "Google Privacy Policy",
        url: "https://policies.google.com/privacy",
      },
      {
        name: "_ga",
        duration: "2 years",
        purpose: "Used to store and count pageviews",
        providerPolicy: "LinkedIn Privacy Policy",
        url: "https://www.linkedin.com/legal/privacy-policy",
      },
      {
        name: "MR",
        duration: "1 week",
        purpose:
          "Used by Microsoft Clarity to indicates whether to refresh MUID",
        providerPolicy: "Microsoft Privacy Policy",
        url: "https://privacy.microsoft.com/en-us/privacystatement",
      },
      {
        name: "SM",
        duration: "Session",
        purpose:
          "Used by Microsoft Clarity in synchronizing the MUID across Microsoft domain",
        providerPolicy: "Microsoft Privacy Policy",
        url: "https://privacy.microsoft.com/en-us/privacystatement",
      },
      {
        name: "lidc",
        duration: "1 day",
        purpose: "to provide load balancing functionality.",
        providerPolicy: "LinkedIn Privacy Policy",
        url: "https://www.linkedin.com/legal/privacy-policy",
      },
      {
        name: "AnalyticsSyncHistory",
        duration: "30 days",
        purpose: "Used to store and track visits across websites",
        providerPolicy: "LinkedIn Privacy Policy",
        url: "https://www.linkedin.com/legal/privacy-policy",
      },
      {
        name: "_gaexp",
        duration: "Varies, 93 days max",
        purpose:
          "Used to store ID's of experiments and sessions for A/B testing",
        providerPolicy: "Google Privacy Policy",
        url: "https://policies.google.com/privacy",
      },
      {
        name: "li_sugr",
        duration: "90 days",
        purpose:
          "Used by LinkedIn to make a probabilistic match of a user's identity outside the Designated Countries",
        providerPolicy: "LinkedIn Privacy Policy",
        url: "https://www.linkedin.com/legal/privacy-policy",
      },
    ],
  },
  {
    title: "Functional and Preferences Cookies",
    description:
      "These cookies are used to enhance user experience by remembering preferences and settings.",
    cookies: [
      {
        name: "bscookie",
        duration: "1 year",
        purpose:
          "Used for remembering that a logged in user is verified by two factor authentication and has previously logged in",
        providerPolicy: "LinkedIn Privacy Policy",
        url: "https://www.linkedin.com/legal/privacy-policy",
      },
      {
        name: "bcookie",
        duration: "1 Year",
        purpose:
          "Browser Identifier cookie to uniquely identify devices accessing LinkedIn to detect abuse on the platform and diagnostic purposes",
        providerPolicy: "LinkedIn Privacy Policy",
        url: "https://www.linkedin.com/legal/privacy-policy",
      },
      {
        name: "NID",
        duration: "6 months",
        purpose: "Used by Google to store user preferences",
        providerPolicy: "Google Privacy Policy",
        url: "https://policies.google.com/privacy",
      },
    ],
  },
  {
    title: "Advertising Cookies",
    description:
      "These are used to tailor relevant ads to users and measure ad campaign effectiveness. At Tomedes, they primarily support our external advertising initiatives.",
    cookies: [
      {
        name: "test_cookie",
        duration: "1 day",
        purpose:
          "The test_cookie is set by doubleclick.net and is used to determine if the user's browser supports cookies.",
        providerPolicy: "Google Privacy Policy",
        url: "https://policies.google.com/privacy",
      },
      {
        name: "MUID",
        duration: "1 Year",
        purpose:
          "Used by Microsoft clarity to identify unique web browsers visiting Microsoft sites. These cookies are used for advertising, site analytics, and other operational purposes.",
        providerPolicy: "Microsoft Privacy Policy",
        url: "https://privacy.microsoft.com/en-us/privacystatement",
      },
      {
        name: "_gcl_au",
        duration: "90 days",
        purpose: "Used to store and track conversions",
        providerPolicy: "Google Privacy Policy",
        url: "https://policies.google.com/privacy",
      },
      {
        name: "UserMatchHistory",
        duration: "30 days",
        purpose: "Used to provide ad delivery and retargeting",
        providerPolicy: "LinkedIn Privacy Policy",
        url: "https://www.linkedin.com/legal/privacy-policy",
      },
      {
        name: "ANONCHK",
        duration: "Session",
        purpose:
          "Indicates whether MUID is transferred to ANID, a cookie used for advertising. Clarity doesn't use ANID and so this is always set to 0",
        providerPolicy: "Microsoft Privacy Policy",
        url: "https://privacy.microsoft.com/en-us/privacystatement",
      },
      {
        name: "SRM_B",
        duration: "1 year 24 days",
        purpose: "Used by Microsoft Advertising as a unique ID for visitors",
        providerPolicy: "Microsoft Privacy Policy",
        url: "https://privacy.microsoft.com/en-us/privacystatement",
      },
      {
        name: "MUID",
        duration: "1 year",
        purpose:
          "Used by Microsoft Bing to identify unique web browsers visiting Microsoft sites. These cookies are used for advertising, site analytics, and other operational purposes",
        providerPolicy: "Microsoft Privacy Policy",
        url: "https://privacy.microsoft.com/en-us/privacystatement",
      },
      {
        name: "gclid",
        duration: "30 days",
        purpose:
          "Used by Google Ads to track user interaction after clicking on an ad",
        providerPolicy: "Google Privacy Policy",
        url: "https://policies.google.com/privacy",
      },
    ],
  },
];

const list= [
      {
        browser:"Google Chrome",
        url:'https://support.google.com/chrome/answer/95647',
      },
      {
        browser:"Mozilla Firefox",
        url:'https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'
      },
      {
        browser:"Microsoft Edge",
        url:"https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy"
      },
      {
        browser:"Apple Safari",
        url:"https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
      },
      {
        browser:"Opera",
        url:'https://help.opera.com/en/latest/web-preferences/#cookies'
      },
    ]

function Cookiepolicy() {
   const { toggleStates, setToggleStates, setShowCookie,setCookiePreference,cookiePreference } = useContext(DataContext);


  return (
    <>
      <Layout>
        <SEO
          title="Cookie Policy - Tomedes"
          description="Review Tomedes' transparent Cookie Policy. Learn about cookie types, their functionalities, and how we use them to enhance your online experience."
          slug="/legal/cookie-policy"
        />
        <section className="bg-[#FFFFFF] mx-auto max-w-7xl  p-4 pb-24  mt-[30px]">
          <Breadcrumb path={["Legal Policies", "Cookie Policy"]} />

          <div className="mt-[50px]">
            <h1 className="text-5xl md:leading-none leading-[50px] font-bold">
              Cookie Policy
            </h1>
            <p className="font-opensans leading-8 mt-5">
              At Tomedes, we value transparency and your right to privacy. This
              policy informs you about the cookies we use, ensuring you have a
              clear understanding of their purpose and how they impact your
              browsing experience.
            </p>
          </div>

          <div className="mt-8">
            <div className="font-opensans gap-[30px] text-[#131313] ">
              <h2 className="font-bold text-[40px] leading-[55px] font-opensans">
                What Are Cookies?
              </h2>
              <p className="font-normal leading-8 text-[16px] py-[20px]">
                Cookies are small text files generated by websites that you
                browse. Their primary role is to store information about your
                online interactions, streamlining and enhancing your web
                experience. This means websites can retain your login status,
                memorize your user preferences, and deliver content that's
                relevant to your locale.
              </p>
              <p className="font-normal leading-8 text-[16px] py-[20px]">
                There are two types of cookies:
              </p>
              <p className="font-normal leading-8 text-[16px] py-[20px]">
                <span className="font-semibold text-xl">
                  {" "}
                  First-party cookies:
                </span>{" "}
                These are set directly by the website you are visiting, as
                indicated in your browser's address bar.
              </p>
              <p className="font-normal leading-8 text-[16px] py-[20px]">
                <span className="font-semibold text-xl">
                  {" "}
                  Third-party cookies:
                </span>{" "}
                These come from external sources incorporated into the site
                you're on. For instance, a website might include images,
                advertisements, or other content from different domains. These
                external entities can set cookies to tailor and optimize your
                browsing experience.
              </p>
            </div>
            <div className="font-opensans gap-[30px] text-[#131313]  mt-7">
              <h2 className="font-bold text-[40px] leading-[55px] font-opensans">
                Categories and List of Cookies We Use in Our Website
              </h2>
              <p className="font-normal leading-8 text-[16px] py-[20px]">
                Tomedes exclusively utilizes third-party cookies, set by our
                trusted partners, to offer you an optimized experience on our
                platform. As such we’ve included a link to our partner’s privacy
                policy pages to further inform you of how they handle your
                data. 
              </p>
            </div>
          </div>

          {allCookiesData?.map((cookieData, i) => (
            <div key={i} className="mt-[50px]">
              <h1 className="text-[40px]  font-primary leading-[50px] font-semibold">
                {cookieData.title}
              </h1>
              <p className="font-opensans leading-8 mt-5">
                {cookieData.description}
              </p>
              <CookiesTable tableData={cookieData.cookies} />
              <MobileTable
                tableData={cookieData.cookies}
                length={cookieData.cookies.length}
              />
            </div>
          ))}

          <h1 className="text-[40px] mt-8  font-primary leading-[50px] font-semibold">
            Managing Cookies
          </h1>

          <div className="flex flex-col mt-8 gap-8">
            <div className={`rounded-lg  first:mt-0  mt-7 text-[#131313]`}>
              <h1
                className={`text-4xl
                  font-primary  font-semibold `}
              >
                Choice & Control
              </h1>
              <p className="font-opensans leading-8 mt-5 ">
                You can manage or disable cookies via your browser settings.
                Here's how to manage cookies on popular browsers:
              </p>

              {list?.map((text, i) => {
                return (
                  <li
                    key={i}
                    className="font-opensans underline underline-offset-4 leading-8 mt-5 ml-5 "
                  >
                    <a
                      href={text.url}
                      target="_blank"
                      className="text-[#0000EE] visited:text-[#551A8B]"
                    >
                      {text.browser}
                    </a>
                  </li>
                );
              })}
            </div>
            {/* <div className={`rounded-lg  first:mt-0  mt-7 text-[#131313]`}>
              <h1
                className={`text-4xl
                  font-primary  font-semibold `}
              >
                Consent
              </h1>

              <p className="font-opensans leading-8 mt-5 ">
                We'll seek consent before placing non-essential cookies. You can
                withdraw consent or modify preferences anytime.
              </p>
              <p
                className="font-opensans leading-8 mt-5 text-[#0000EE] underline underline-offset-2 cursor-pointer"
                onClick={() => setCookiePreference(true)}
              >
                Change my preferences
              </p>
            </div> */}

            <div className={`rounded-lg  first:mt-0  mt-7 text-[#131313]`}>
              <h1
                className={`text-[40px] leading-12
                  font-primary  font-semibold `}
              >
                Managing Your Personal Data
              </h1>

              <p className="font-opensans leading-8 mt-5 ">
                If you wish to have all your data purged from our systems,
                please visit our{" "}
                <Link
                  to="/datadestruction"
                  className="text-[#0000EE] underline underline-offset-2"
                >
                  Data Removal Request{" "}
                </Link>{" "}
                page. Here, you can request a complete removal of your personal
                data from our website.{" "}
              </p>
            </div>
          </div>
          <div className=" mt-14 my-5 text-[#131313]">
            <h1 className="text-[40px]  font-primary font-semibold leading-12 ">
              Changes to Our Cookie Policy
            </h1>
            <p className="font-opensans leading-8 mt-5 ">
              We may occasionally update our cookie policy to reflect changes in
              our practices and services. If we make significant changes, we
              will notify you via our{" "}
              <Link
                to="/translator-hub.php"
                className="text-[#0000EE] underline underline-offset-2"
              >
                blog
              </Link>{" "}
              before they become effective. We encourage you to periodically
              review this page for the latest information on our cookie
              practices. Your continued use of our services after any changes or
              revisions to this cookie policy indicates your agreement to the
              terms of the revised policy.
            </p>
          </div>

          <div className="rounded-lg mt-14 my-5 text-[#131313]">
            <h1 className="text-[40px]  font-primary font-semibold leading-9 ">
              Contact Us
            </h1>
            <p className="font-opensans leading-8 mt-5 ">
              If you have any questions or concerns about this cookie policy,
              how we use cookies, or about your data privacy in general, we're
              here to help.
            </p>

            <div className="font-opensans flex flex-col leading-8 mt-5 ">
              <span>Email: support@tomedes.com</span>
              <span>Phone: US: +1 985 239 0142</span>
              <span>UK: +44 (0)16 1509 6140 </span>
            </div>
            <p className="font-opensans leading-8 mt-5 ">
              For faster response, please include "Cookie Policy Inquiry" in
              your email subject line.
            </p>
            <div className="font-opensans flex flex-col leading-8 mt-5 ">
              <span>Mailing Address:</span>
              <span>Tomedes Ltd - USA 9450 SW</span>
              <span>Gemini Dr #34540,</span>
              <span>Beaverton, Oregon</span>
            </div>
            <p className="font-opensans leading-8 mt-5 ">
              If you believe your concerns have not been addressed
              satisfactorily, you also have the right to lodge a complaint with
              the appropriate data protection authority.
            </p>
          </div>

          <div className="flex flex-col items-center mt-[50px]">
            <img
              src={backToTopIcon}
              alt="backIcon"
              className="cursor-pointer"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            />
            <span className="mt-4 font-primary">Back To Top</span>
          </div>
        </section>
        {cookiePreference && <ManageCookie />}
      </Layout>
    </>
  );
}

export default Cookiepolicy;
