import React, { useState } from "react";
const headers = [
  "Cookie Name",
  " Duration",
  "Purpose",
  `Provider's Privacy Policy`,
];

const MobileTable = ({ tableData, length }) => {
  const [index, setIndex] = useState(0);
  const handlePrevious = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };
  const handleNext = () => {
    if (index < 3) {
      setIndex(index + 1);
    }
  };

  return (
    <div className="lg:hidden block px-4">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-[#192A3E] text-white">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    {headers[index]}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {tableData?.map((data, i) => (
                  <tr key={i}>
                    <td
                      className={`max-w-[80%] py-4 pl-4 pr-3 text-sm font-medium  ${
                        index === 3 ? "underline underline-offset-2" : "text-gray-900"
                      } sm:pl-1`}
                    >
                      {index === 0 ? (
                        data.name
                      ) : index === 1 ? (
                        data.duration
                      ) : index === 2 ? (
                        data.purpose
                      ) : (
                        <a href={data.url} target="blank" className="text-[#0000EE] visited:text-[#551A8B]">
                          {" "}
                          {data.providerPolicy}
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-between text-[#3F3F46] font-opensans text-lg  mt-5">
              Showing 1- {length} out of 32 results
            </div>

            <div className="flex justify-between text-[#3F3F46] font-opensans text-lg  mt-5">
              <button
                onClick={handlePrevious}
                className={`flex items-center gap-2 ${
                  index === 0 && "opacity-50"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M3 12H21M7 16L3 12L7 16ZM3 12L7 8L3 12Z"
                    stroke="#3F3F46"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>Previous</span>
              </button>
              <button
                onClick={handleNext}
                className={`flex items-center gap-2 ${
                  index === 3 && "opacity-50"
                }`}
              >
                <span>Next</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M17 8L21 12M21 12L17 16M21 12H3"
                    stroke="#3F3F46"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileTable;
