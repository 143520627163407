import { Link } from "gatsby";
import React from "react";
export default function CookiesTable({ tableData }) {
  return (
    <div className="lg:block hidden">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-[#192A3E] text-white">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Cookie Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                  >
                    Duration
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                  >
                    Purpose
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                  >
                    Provider's Privacy Policy
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {tableData?.map((data, i) => (
                  <tr key={data.i}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-1">
                      {data.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {data.duration}
                    </td>
                    <td className=" min-w-[250px] max-w-sm px-3 py-4 text-sm  leading-8 text-gray-500">
                      {data.purpose}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm  underline underline-offset-2">
                      <a href={data.url} target="blank" className="text-[#0000EE] visited:text-[#551A8B]">
                        {" "}
                        {data.providerPolicy}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
